<script>
import router from "../../router/routes";
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar-store";
import Footer from "@/components/footer";
import Config from '@/components/widgets/store-config';
import { api } from "@/state/services";

export default {
  components: { NavBar, SideBar, Footer, Config },
  beforeCreate: function() {
    document.body.className = 'main';
  },
  data() {
    return {
      isMenuCondensed: false,

      cart: {
        items: {
          total: 0,
          list: null
        },
        subtotal: '0.00',
        shipping: {
          method: '',
          value: '0.00',
        },
        total: '0.00'
      }
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    getCart() {
      if (!localStorage.cart) {
        api
          .get('store/cart')
          .then(response => {
            if (response.data.status=='success') {
              this.cart.items.loading = false
              this.setStorage(response.data)
            }
          })
          .catch(error => {
            this.cart.errored = error
          })
          .finally(() => {
            this.cart.items.loading = false
          })
      } else {
        this.cart = JSON.parse(localStorage.cart)
        this.cart.items.loading = false
      }
    },
    setStorage(data) {
      this.cart.items.total = data.items.total
      this.cart.items.list = data.items.list
      this.cart.subtotal = data.subtotal
      this.cart.shipping = data.shipping
      this.cart.total = data.total

      localStorage.cart = JSON.stringify(this.cart)
    },
  },
  mounted() {
    this.getCart()
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :isCondensed="isMenuCondensed" />
    <SideBar :isCondensed="isMenuCondensed" />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <Config ref="config" :total="cart.items.total" />
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<style scoped>
  .page-content {
    padding: calc(50px + 24px) calc(24px / 2) 60px calc(24px / 2);
  }
</style>
